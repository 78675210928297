
import {directive} from 'vue-awesome-swiper';
import Banner from '~/components/catalog/banner/index';

export default {
  name: 'BannerSlider',
  components: {
    Banner,
  },
  directives: {
    swiper: directive,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
    autoplay: {
      type: [Object, Boolean],
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sliderOptions: {
        autoplay: this.autoplay,
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        slidesPerView: 1,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        loop: false,
        preloadImages: false,
        pagination: {
          el: `.${this.id} .banner-slider__dotts`,
          clickable: false,
        },
        // Arrows
        navigation: {
          nextEl: `.${this.id} .banner-slider__arrow_next`,
          prevEl: `.${this.id} .banner-slider__arrow_prev`,
        },
      },
    }
  },
  computed: {
    swiperSlider() {
      return this.sliderBanner
    },
  },
  mounted() {
    if (!this.swiperSlider) return

    this.swiperSlider.on('realIndexChange', () => {
      this.$emit('changeSlide', this.swiperSlider.realIndex)
    })

    if (this.banners.length === 1) {
      this.swiperSlider.destroy()
    }
  },
}
